export default class UploadQueue {
    maxConcurrentUploads: number = 4;
    private activeUploads: number = 0;
    private ticketQueue: (() => void)[] = [];

    public decrementActiveUploads() {
        this.activeUploads--;
    }

    constructor(pMaxConcurrent:number = 4){
        this.maxConcurrentUploads = pMaxConcurrent;
    }

    public getTicket(): Promise<void> {
        return new Promise<void>((resolve) => {
            this.ticketQueue.push(resolve); 
            this.processTicketQueue();
        });
    }

    public clearActiveUploads()
    {
        this.activeUploads = 0;
        this.ticketQueue.splice(0, this.ticketQueue.length);
    }

    public processTicketQueue() {
        if (this.ticketQueue.length === 0 || this.activeUploads >= this.maxConcurrentUploads) {
            return;
        }

        this.activeUploads++;
        const nextTicketResolver = this.ticketQueue.shift(); 

        if (nextTicketResolver) {
            nextTicketResolver();
        }
    }
}
